import AccountService from '@/services/account.service.js'
import EventBus from '@/util/EventBus.js'
import getSymbolFromCurrency from 'currency-symbol-map'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'Balance',
  components: {
    HeaderTopDashboard,
  },
  data: function () {
    return {
      loading: false,
      activeNotification: false,
      user: null,
      ready: false,
      showErrorEmail: false,
      notification: {
        low_balance: null,
        low_balance_level: null,
        emails: [],
      },
      balance: null,
      limite_credito: null,
      currencySymbol: getSymbolFromCurrency(JSON.parse(localStorage.getItem('user')).currencyCode),
    }
  },
  mounted () {

  },
  created () {
    this.loadBalance()
  },
  methods: {
    setReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
      this.activeNotification = this.notification.low_balance === 1
    },

    loadBalance () {
      AccountService.getBalance().then((data) => {
        this.notification.low_balance = data.low_balance
        this.notification.low_balance_level = data.low_balance_level
        this.notification.emails = data.emails.length > 0 ? data.emails : []
        this.balance = data.balance
        this.limite_credito = data.limite_credito
        this.user = data.user
        this.setReady()
      })
    },

    changeNotification () {
      if (this.activeNotification === true) {
        this.notification.low_balance = 1
      } else {
        this.notification.low_balance = 0
      }
    },

    validateEmail (email) {
      var valid = true
      email.forEach(mail => {
        // eslint-disable-next-line no-useless-escape
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))) {
          valid = false
        }
      })
      return valid
    },

    validateUpdate () {
      if (this.activeNotification === false) {
        return true
      } else if (this.activeNotification === true && !this.validateEmail(this.notification.emails)) {
        return false
      } else {
        return true
      }
    },

    save () {
      const validateUpdate = this.validateUpdate()

      if (validateUpdate) {
        this.loading = true
        AccountService.saveBalanceAlert(this.notification)
          .then(
            () => {
              EventBus.$emit('showAlert', 'success', this.$t('La alerta de saldo se ha modificado correctamente').toString())
            },
            (error) => {
              if (error.response.status === 422) {
                EventBus.$emit('showAlert', 'danger', 'Problemas al actualizar las alertas de saldo')
              }

              EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
            },
          ).finally(
            () => {
              this.loading = false
            },
          )
      } else {
        EventBus.$emit('showAlert', 'danger', this.$t('Al menos uno de los correos electrónicos es invalido'))
      }
    },

    validateSectionsBlockeds (section) {
      return !this.sectionsBlockeds.includes(section)
    },
  },
}
